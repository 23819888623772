import { render, staticRenderFns } from "./Tresoreriesdetail.vue?vue&type=template&id=b0d44560&scoped=true&"
import script from "./Tresoreriesdetail.js?vue&type=script&lang=js&"
export * from "./Tresoreriesdetail.js?vue&type=script&lang=js&"
import style0 from "./Tresoreriesdetail.vue?vue&type=style&index=0&id=b0d44560&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0d44560",
  null
  
)

export default component.exports